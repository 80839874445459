document.addEventListener('DOMContentLoaded', (e) => {

    // scroll-triggered CTA block
    let animateFn = (entries) => entries.forEach(entry => {
        if (entry.isIntersecting) {
            $(entry.target).addClass('animate');
        } 
    });
    const config = { rootMargin: '0px 0px',	threshold: .5 };
    let observer = new IntersectionObserver(animateFn, config);
    document.querySelectorAll('.block--cta').forEach(el => observer.observe(el));

});
